import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

const Confirmation = () => {
  return (
    <div className="min-h-screen center-content flex-col pb-[20vh] p-4">
      <Helmet>
        <title>Din bestilling er bekreftet</title>
      </Helmet>
      <div className="mb-24">
        <p className="text-lg">Din bestilling er bekreftet</p>
        <h1 className="text-3xl font-bold text-primary-accent mt-2">
          Takk for at du bestilte hos oss!
        </h1>
      </div>
      <Link
        to={"/"}
        className="text-lg font-bold bg-focus rounded-full p-2 px-4 text-primary-accent shadow-lg hover:!bg-opacity-75 hover:scale-105 transition-all duration-100 ease-in-out"
      >
        Tilbake til hovedsiden
      </Link>
    </div>
  )
}

export default Confirmation
