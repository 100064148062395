// Assets
import { Helmet } from "react-helmet"
import tannlegevakt from "../../assets/images/tannlegevakt.webp"
import OrderButton from "../base/order-button/order-button"
import { Link } from "react-router-dom"

const Tannlegevakt = () => {
  return (
    <div className="info w-full center-content p-8 text-dark">
      <Helmet>
        <title>Tannlegevakt</title>
      </Helmet>
      <div className="max-w-screen-xl">
        <div className="center-content flex-col mb-8 text-center">
          <h1>Tannlegevakt: Rask hjelp ved akutt tannlegebehov</h1>
          <p className={`!mb-8 mt-2 font-bold`}>Vakttelefon: 73 52 00 00</p>
          <p className={`!mb-8 mt-2`}>
            Ring vår vakttelefon med en gang dersom det er akutt. Vår
            tannlegevakt er åpen 365 dager i året - kvelder, helger og
            helligdager. Tannlegevakt er en viktig tjeneste når du plutselig
            trenger akutt tannbehandling. I denne artikkelen utforsker vi hvilke
            tjenester som tilbys, og hvordan du kan få hjelp når du trenger det
            mest.
          </p>
          <img
            src={tannlegevakt}
            alt="tannlegevakt"
            className="w-full max-h-[60vh] object-cover object-center"
          />
        </div>
        <div className="mb-8">
          <h2>Hva er en tannlegevakt?</h2>
          <p>
            En tannlegevakt er en tjeneste designet for å håndtere akutt behov
            for tannlege utenfor ordinær åpningstid. Dette inkluderer
            situasjoner som tannsmerter, skader på flere tenner, eller andre
            tannproblemer som ikke kan vente til din vanlige tannlege har ledig
            time. Tannlegevakten i Trondheim tilbyr en rask løsning for dem som
            trenger akutt hjelp utenom den vanlige åpningstiden.
          </p>
        </div>
        <div className="mb-8">
          <h2>Når bør du søke hjelp hos tannlegevakt?</h2>
          <p>
            Det er viktig å vite når du bør ta kontakt med en tannlegevakt.
            Typiske tilfeller hvor du akutt trenger hjelp kan være:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>
              Alvorlig tannverk som ikke lar seg lindre med smertestillende.
            </li>
            <li>
              Skader på tennene etter ulykker, som for eksempel ved fall eller
              sportsskader.
            </li>
            <li>
              Akutt infeksjon som manifesterer seg gjennom hevelse eller
              alvorlig ubehag.
            </li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Tjenester tilgjengelig hos tannlegevakt</h2>
          <p>
            Tannlegevakten hos oss i Lade Tannhelsesenter tilbyr ulike typer
            akutt tannbehandling for å imøtekomme behovet. Her får du:
          </p>
          <ul className="list-disc pl-4 mb-4">
            <li>Nødbehandling for akutte smerter.</li>
            <li>Behandling av tannskader og tanninfeksjoner.</li>
            <li>Rådgivning og førstehjelpstiltak over telefon før ankomst.</li>
          </ul>
        </div>
        <div className="mb-8">
          <h2>Hvordan bestille akutt tannlegetime i Trondheim</h2>
          <p>
            Å bestille time hos tannlegevakten vår er enkelt - ring 73 52 00 00,
            så hjelper vi deg umiddelbart. Du kan ringe dette nummeret for akutt
            tannlege i Trondheim eller besøke{" "}
            <a
              className="link"
              href="https://myanita.no/clinic/41"
              target="_blank"
              rel="noreferrer"
            >
              denne siden
            </a>{" "}
            for å bestille direkte. Det er viktig å handle raskt for å redusere
            risikoen for ytterligere komplikasjoner.
          </p>
          <h3>Lokalisering av tannlegevakten</h3>
          <p>
            Tannlegesenteret ligger sentralt på Lade, like ved City Lade
            kjøpesenter, med gode transportmuligheter. Det er enkelt å komme seg
            dit både med offentlig transport og egen bil. Informasjon om eksakt
            adresse og veibeskrivelser finner du{" "}
            <a
              className="link"
              href="https://www.google.com/maps/dir//H%C3%A5kon+Magnussons+gate+10,+7041+Trondheim/@63.4444187,10.436472,16z/data=!4m18!1m8!3m7!1s0x466d3105988fa5bd:0x2c461931548ed354!2sH%C3%A5kon+Magnussons+gate+10,+7041+Trondheim!3b1!8m2!3d63.4444164!4d10.4416218!16s%2Fg%2F11c43stgcc!4m8!1m0!1m5!1m1!1s0x466d3105988fa5bd:0x2c461931548ed354!2m2!1d10.4416218!2d63.4444164!3e1?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              her
            </a>
            .
          </p>
        </div>
        <div className="mb-8">
          <h2>Vår tilnærming til akutt tannhelse</h2>
          <p>
            Ved vår tannlegevakt i Trondheim, er vi opptatt av å tilby en trygg
            og smertefri opplevelse for alle våre pasienter - og vi er eksperter
            på behandling av kunder med{" "}
            <Link to={"/info/tannlegeskrekk"} className="link">
              tannlegeskrekk
            </Link>
            . Vi forstår at mange opplever nervøsitet eller angst for
            tannbehandling, og vår målsetting er å tilrettelegge for en
            beroligende og støttende atmosfære.
          </p>
        </div>
        <div className="mb-8">
          <h2>Oppsummering</h2>
          <p>
            Å ha behov for akutt hjelp fra en tannlege kan være en stressende
            opplevelse. Vi er her for å hjelpe deg med alle typer skader. Husk,
            rask handling er nøkkelen til å forhindre videre skade og sikre best
            mulig resultat for din tannhelse. Vil du lese mer om våre tjenester
            eller bestille en akutt tannlegetime? Besøk vårt nettsted eller
            kontakt oss direkte på 73 52 00 00. Vi er her for å sørge for at du
            mottar den hjelpen du trenger, når du trenger den mest.
          </p>
        </div>
        <div className="center-content mt-12 mb-10">
          <OrderButton />
        </div>
      </div>
    </div>
  )
}

export default Tannlegevakt
