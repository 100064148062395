import { useEffect } from "react"
// import ReactPlayer from "react-player"

// Components
import FrontInfo from "./components/front-info"
import OurServices from "./components/our-services"
import Articles from "./components/articles"
import Dentists from "./components/dentists"
import TopicalCarousel from "../topical/topical-carousel"

// Assets
import FrontImage from "../../assets/images/lade_tannhelsesenter.webp"
import { Helmet } from "react-helmet"
import Reviews from "./components/reviews"

const Dashboard = () => {
  useEffect(() => {
    const fadeArrow = document.getElementById("fade-arrow")
    if (fadeArrow) setTimeout(() => (fadeArrow.style.opacity = "0"), 2000)
  }, [])

  return (
    <div>
      <Helmet>
        <title>Din lokale tannlege i Trondheim - Lade Tannhelsesenter</title>
        <link rel="canonical" href="https://ladetannhelsesenter.no" />
      </Helmet>
      <div
        className={`flex flex-col items-center overflow-hidden -mt-8 md:mt-0`}
      >
        <div
          className="overflow-hidden mb-8 md:-mb-2rem"
          style={{ zIndex: -2 }}
        >
          <div className="relative w-screen flex items-start justify-center max-h-[85vh]">
            <img
              src={FrontImage}
              alt="dashboard banner lade tannhelsesenter"
              className="w-full lg:-mt-24"
            />
            <header className="absolute bottom-[2vh] md:bottom-[5rem] left-0 text-2xl md:text-6xl bg-light font-bold p-2 pl-4 md:p-8 rounded-r bg-opacity-75 text-primary-accent">
              <h1>Tannlege Trondheim</h1>
            </header>
            {/* <ReactPlayer
            url="videos/demo_video.mp4"
            width="100%"
            height="100%"
            muted={true}
            playing={true}
            controls={true}
            loop={true}
            pip={false}
            config={{
              file: { attributes: { disablePictureInPicture: true } },
            }}
          /> */}
          </div>
        </div>
        <FrontInfo className="mb-8" />
        <Reviews className="mb-16" />
        <OurServices className="mb-8" />
        <Articles />
        <Dentists />
        <TopicalCarousel />
        <div id="location-info" className="w-full">
          <iframe
            title="google maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3567.422302525684!2d10.436471961369609!3d63.44441870569739!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x466d3105988fa5bd%3A0x2c461931548ed354!2sH%C3%A5kon%20Magnussons%20gate%2010%2C%207041%20Trondheim!5e0!3m2!1sen!2sno!4v1683918331645!5m2!1sen!2sno"
            width="100%"
            height="400px"
            allowFullScreen={false}
            aria-hidden="false"
            tabIndex={0}
          />
        </div>
      </div>
    </div>
  )
}

export default Dashboard
